//export const PAYROLL_OLD_PATH = window?.env?.REACT_OLD_SERVER_PAYROLL_URL;

export const CHUNK_SIZE = 5;
export const CHUNK_DELAY = 1000;

export const RUN_PAYROLL_FILTERS = "report/get_filters";
// export const GET_EMPLOYEE_LIST = 'process/employee_list';
export const EMP_STRUCTURE_DETAILS_PLAN = "process/emp_structure_plans_details/"
export const ORG_STRUCTURE_CALC_TYPE = "org_config/org_structure_calculation_type"
export const GET_ORG_TOTAL_SALARY = "getOrgTotalSalary"
export const SALARY_PROJETION_REPORT = "reports/salary_projection_report"
export const EMPLOYEE_ATTENDANCE = "process/get_employees_attendance";
export const SELECTED_BULK_RECOMPUTE = "process/selected_bulk_recompute";
export const GET_EMPLOYEE_LIST = "newprocess/employee_list";
export const CALCULATE_GRATUITY = "process/calculate_gratuity";
export const FREEZE_EMPLOYEE_ATTENDANCE = "process/freeze_employee_attendance";
export const GENRATE_EMPLOYEE_PAYROLL = "process/generate_employees_payroll_new";
export const BULK_REGENRATE_PAYROLL_NEW = "process/bulk_regenerate_employees_payroll_new";
export const SAVE_EMPLOYEE_PAYROLL = "process/save_employees_payroll";
export const PUBLISH_PAY_SLIPS = "process/publish_payslips";
export const INT_FREEZE_PAYROLL = "process/freeze_payroll"
export const GET_FILTERED_EMP = "international/process/get_filtered_employees"
export const PROCESS_EMPLOYEE_ARREAR = "international/process/process_employees_arrear"
export const SAVE_EMPLOYEE_ARREAR = "international/process/save_employees_arrear"
export const ADD_EMP_PAYROLL_REMARKS = "process/add_employees_payroll_remarks";
export const CUMPUTE_EMPLOYEE_GRATUITY = "employees/calculate_gratuity";
export const ADD_EMPLOYEE_PAYROLL_REMARKS = "process/add_employees_payroll_remarks";
export const GET_QUICK_BOOK_INTEGRATION_DETAILS = "integration/quick_book/get_details";
export const INTITIATE_QUICK_BOOK_INTEGRATION = "integration/quick_book/initiate_integration";
export const VERIFY_QUICK_BOOK_INTEGRATION = "integration/quick_book/verify_integration";
