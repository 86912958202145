import display from "./lang";
import { CurrencyRupee } from "@mui/icons-material";
import { Button, Stack, Tooltip } from "@mui/material";
import pdfIcons from "./assets/images/icons/pdf.svg";
import csvIcons from "./assets/images/icons/sheet.svg";
import imgIcons from "./assets/images/icons/image.svg";
import wordIcons from "./assets/images/icons/word.svg";
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { MainRoutePath } from "./constants";
import { getUser } from "./AppRedux/Actions";
import { INDIAN_COUNTRY_ID } from "./pages/Salary/constant";
import * as XLSX from 'xlsx';

export const INDIAN_COUNTRY_CODE = 101;
const _plural = {
    'rules': {
        '(s)tatus$': '$1tatuses', '(quiz)$': '$1zes', '^(ox)$': '$1$2en', '([m|l])ouse$': '$1ice', '(matr|vert|ind)(ix|ex)$': '$1ices', '(x|ch|ss|sh)$': '$1es', '([^aeiouy]|qu)y$': '$1ies', '(hive)$': '$1s', '(?:([^f])fe|([lre])f)$': '$1$2ves', 'sis$': 'ses', '([ti])um$': '$1a', '(p)erson$': '$1eople', '(?<!u)(m)an$': '$1en', '(c)hild$': '$1hildren', '(buffal|tomat)o$': '$1$2oes', '(alumn|bacill|cact|foc|fung|nucle|radi|stimul|syllab|termin)us$': '$1i', 'us$': 'uses', '(alias)$': '$1es', '(ax|cris|test)is$': '$1es', 's$': 's', '^$': '', '$': 's',
    },
    'uninflected': [
        '.*[nrlm]ese', '.*data', '.*deer', '.*fish', '.*measles', '.*ois', '.*pox', '.*sheep', 'people', 'feedback', 'stadia'
    ],
    'irregular': {
        'atlas': 'atlases', 'beef': 'beefs', 'brief': 'briefs', 'brother': 'brothers', 'cafe': 'cafes', 'child': 'children', 'cookie': 'cookies', 'corpus': 'corpuses', 'cow': 'cows', 'criterion': 'criteria', 'ganglion': 'ganglions', 'genie': 'genies', 'genus': 'genera', 'graffito': 'graffiti', 'hoof': 'hoofs', 'loaf': 'loaves', 'man': 'men', 'money': 'monies', 'mongoose': 'mongooses', 'move': 'moves', 'mythos': 'mythoi', 'niche': 'niches', 'numen': 'numina', 'occiput': 'occiputs', 'octopus': 'octopuses', 'opus': 'opuses', 'ox': 'oxen', 'penis': 'penises', 'person': 'people', 'sex': 'sexes', 'soliloquy': 'soliloquies', 'testis': 'testes', 'trilby': 'trilbys', 'turf': 'turfs', 'potato': 'potatoes', 'hero': 'heroes', 'tooth': 'teeth', 'goose': 'geese', 'foot': 'feet', 'sieve': 'sieves'
    }
};

const _singular = {
    'rules': {
        '(s)tatuses$': '$1$2tatus', '^(.*)(menu)s$': '$1$2', '(quiz)zes$': '$1', '(matr)ices$': '$1ix', '(vert|ind)ices$': '$1ex', '^(ox)en': '$1', '(alias)(es)*$': '$1', '(alumn|bacill|cact|foc|fung|nucle|radi|stimul|syllab|termin|viri?)i$': '$1us', '([ftw]ax)es': '$1', '(cris|ax|test)es$': '$1is', '(shoe)s$': '$1', '(o)es$': '$1', 'ouses$': 'ouse', '([^a])uses$': '$1us', '([m|l])ice$': '$1ouse', '(x|ch|ss|sh)es$': '$1', '(m)ovies$': '$1$2ovie', '(s)eries$': '$1$2eries', '([^aeiouy]|qu)ies$': '$1y', '(tive)s$': '$1', '(hive)s$': '$1', '(drive)s$': '$1', '([le])ves$': '$1f', '([^rfoa])ves$': '$1fe', '(^analy)ses$': '$1sis', '(analy|diagno|^ba|(p)arenthe|(p)rogno|(s)ynop|(t)he)ses$': '$1$2sis', '([ti])a$': '$1um', '(p)eople$': '$1$2erson', '(m)en$': '$1an', '(c)hildren$': '$1$2hild', '(n)ews$': '$1$2ews', 'eaus$': 'eau', '^(.*us)$': '$1', 's$': ''
    },
    'uninflected': [
        '.*data', '.*[nrlm]ese', '.*deer', '.*fish', '.*measles', '.*ois', '.*pox', '.*sheep', '.*ss', 'feedback'
    ],
    'irregular': { 'atlases': 'atlas', 'beefs': 'beef', 'briefs': 'brief', 'brothers': 'brother', 'cafes': 'cafe', 'children': 'child', 'cookies': 'cookie', 'corpuses': 'corpus', 'cows': 'cow', 'criteria': 'criterion', 'ganglions': 'ganglion', 'genies': 'genie', 'genera': 'genus', 'graffiti': 'graffito', 'hoofs': 'hoof', 'loaves': 'loaf', 'men': 'man', 'monies': 'money', 'mongooses': 'mongoose', 'moves': 'move', 'mythoi': 'mythos', 'niches': 'niche', 'numina': 'numen', 'occiputs': 'occiput', 'octopuses': 'octopus', 'opuses': 'opus', 'oxen': 'ox', 'penises': 'penis', 'people': 'person', 'sexes': 'sex', 'soliloquies': 'soliloquy', 'testes': 'testis', 'trilbys': 'trilby', 'turfs': 'turf', 'potatoes': 'potato', 'heroes': 'hero', 'teeth': 'tooth', 'geese': 'goose', 'feet': 'foot', 'sieves': 'sieve', 'foes': 'foe' }

};

const _uninflected = ['Amoyese', 'bison', 'Borghese', 'bream', 'breeches', 'britches', 'buffalo', 'cantus', 'carp', 'chassis', 'clippers', 'cod', 'coitus', 'Congoese', 'contretemps', 'corps', 'debris', 'diabetes', 'djinn', 'eland', 'elk', 'equipment', 'Faroese', 'flounder', 'Foochowese', 'gallows', 'Genevese', 'Genoese', 'Gilbertese', 'graffiti', 'headquarters', 'herpes', 'hijinks', 'Hottentotese', 'information', 'innings', 'jackanapes', 'Kiplingese', 'Kongoese', 'Lucchese', 'mackerel', 'Maltese', '.*?media', 'mews', 'moose', 'mumps', 'Nankingese', 'news', 'nexus', 'Niasese', 'Pekingese', 'Piedmontese', 'pincers', 'Pistoiese', 'pliers', 'Portuguese', 'proceedings', 'rabies', 'research', 'rice', 'rhinoceros', 'salmon', 'Sarawakese', 'scissors', 'sea[- ]bass', 'series', 'Shavese', 'shears', 'siemens', 'species', 'swine', 'testes', 'trousers', 'trout', 'tuna', 'Vermontese', 'Wenchowese', 'whiting', 'wildebeest', 'Yengeese'];

/**
 * merge multiple object to one
 * eg obj_merge({},{x:1},{y:2},{z:3},...);
 * @param {obj} target
 * @returns {object}
 */
export const obj_merge = function () {
    let target = {};
    Object.assign(target, arguments[0] || {});
    let sources = [].slice.call(arguments, 1);
    sources.forEach(function (source) {
        for (const prop in source) {
            target[prop] = source[prop];
        }
    });
    return target;
}

/**
 * Return word in plural form.
 *
 * @param {string} word Word in singular
 * @return {string} Word in plural
 */
export const pluralize = function (word) {
    let cacheUninflected = '(?:' + Object.values(obj_merge(_plural.uninflected, _uninflected)).join('|') + ')';
    let cacheIrregular = '(?:' + Object.keys(_plural.irregular).join('|') + ')';
    let regs = (word || '').match(new RegExp("(.*?(?:\\b|_))(" + cacheIrregular + ")$", "i"));
    if (regs) {
        return regs[1] + regs[2].substr(0, 1) + _plural.irregular[regs[2].toLowerCase()].substr(1);
    }
    if ((word || '').match(new RegExp("/^(" + cacheUninflected + ")$", "i"))) {
        return word;
    }
    for (const rule in _plural.rules) {
        if (word.match(new RegExp(rule, "i"))) {
            return preg_replace(rule, _plural.rules[rule], word);
        }
    }
}

/**
 * Return word in singular form.
 *
 * @param {string} word Word in plural
 * @return {string} Word in singular
 */
export const singularize = function (word) {
    let obj = this;
    let cacheUninflected = '(?:' + Object.values(obj_merge(_singular.uninflected, _uninflected)).join('|') + ')';
    let cacheIrregular = '(?:' + Object.keys(_singular.irregular).join('|') + ')';
    let regs = (word || '').match(new RegExp("(.*?(?:\\b|_))(" + cacheIrregular + ")$", "i"));
    if (regs) {
        return regs[1] + regs[2].substr(0, 1) + _singular.irregular[regs[2].toLowerCase()].substr(1);
    }
    if ((word || '').match(new RegExp("/^(" + cacheUninflected + ")$", "i"))) {
        return word;
    }
    for (const rule in _singular.rules) {
        if (word.match(new RegExp(rule, "i"))) {
            return obj.preg_replace(rule, _singular.rules[rule], word);
        }
    }
    return word;
}

/**
 * Returns the given camelCasedWord as an underscored_word.
 *
 * @param {string} camelCasedWord Camel-cased word to be "underscorized"
 * @return {string} Underscore-syntaxed version of the $camelCasedWord
 */
export const underscore = function (camelCasedWord) {
    let regs = camelCasedWord.match(/([A-Z])/g);
    let r_len = regs.length;
    for (let i = r_len - 1; i >= 0; i--) {
        if (regs[i] !== undefined && regs[i]) {
            camelCasedWord = camelCasedWord.replace(regs[i], ' ' + regs[i]);
        }
    }
    return camelCasedWord.trim().replace(/ /g, '_').toLowerCase();
}

/**
 * Returns the given underscored_word_group as a Human Readable Word Group.
 * (Underscores are replaced by spaces and capitalized following words.)
 *
 * @param {string} lowerCaseAndUnderscoredWord String to be made more readable
 * @return {string} Human-readable string
 */
export const humanize = function (lowerCaseAndUnderscoredWord) {
    return (lowerCaseAndUnderscoredWord || '')
        .replace(/_/g, ' ')
        .split(' ')
        .map(w => { return w.charAt(0).toUpperCase() + w.slice(1); })
        .join(' ');
}

/**
 * Returns the given lower_case_and_underscored_word as a CamelCased word.
 *
 * @param {string} lowerCaseAndUnderscoredWord Word to camelize
 * @return {string} Camelized word. LikeThis.
 */
export const camelize = function (lowerCaseAndUnderscoredWord) {
    return humanize(lowerCaseAndUnderscoredWord).replace(/ /g, '');
}

/**
 * Format string similar to printf();
 * eg format('My name is ?.','Vinay');
 * @param {string} message, replacemen values...
 * @returns {string} formated String.
 */
export const format = function () {
    let message = arguments[0] || ``;
    let values = [].slice.call(arguments, 1);
    if (values == null || !Array.isArray(values) || values.length <= 0) {
        return message;
    }
    let chunkIndex = 0;
    let placeholdersRegex = /\?/g;
    let result = '';
    let valuesIndex = 0;
    let match;

    while (valuesIndex < values.length && (match = placeholdersRegex.exec(message))) {
        result += message.slice(chunkIndex, match.index) + (values[valuesIndex] || '');
        chunkIndex = placeholdersRegex.lastIndex;
        valuesIndex++;
    }
    if (chunkIndex === 0) {
        return message;
    }
    if (chunkIndex < message.length) {
        return result + message.slice(chunkIndex);
    }
    return result;
};

export const preg_replace = function (rule, replacement, word) {
    let regs = word.match(new RegExp(rule, "i"));
    let r_len = regs.length;
    let replaced_word = word.substr(0, regs.index) + replacement;
    for (let i = r_len - 1; i > 0; i--) {
        replaced_word = replaced_word.replace('$' + i, regs[i] || '');
    }
    return replaced_word;
}

/**
* Creates an associative array using `keyPath` as the path to build its keys, and optionally
* `valuePath` as path to get the values. If `valuePath` is not specified, all values will be initialized
* You can optionally group the values by what is obtained when following the path specified in `groupPath`.
*
* @param {array|Object} data Data array from where to extract keys and values
* @param {string} keyPath keyPath A dot-separated string or array for formatting rules.
* @param {string} valuePath valuePath A dot-separated string or array for formatting rules.
* @param {string} groupPath groupPath A dot-separated string.
* @return {Object} Combined Object
*/
export const combine = function (data, keyPath, valuePath, groupPath) {
    let out = {};
    if (typeof data != 'object' || !keyPath || !valuePath || typeof keyPath != 'string' || typeof valuePath != 'string') {
        return out;
    }
    let [pi1, pv1, pvv1] = keyPath.split('.');
    let [pi2, pv2, pvv2] = valuePath.split('.');
    if (['{n}', '{s}', '{*}'].indexOf(pi1) === -1) {
        return out;
    }
    if (!groupPath) {
        groupPath = pi1;
    }
    let [pi3, pv3, pvv3] = groupPath.split('.');
    for (const k in data) {
        let r = data[k];
        if (pi1 !== pi2) { continue; }
        if (pi2 !== pi3) { continue; }
        if (pi1 === '{n}' && isNaN(k)) { continue; }
        if (pi1 === '{s}' && !isNaN(k)) { continue; }
        if (pv1 && Object.hasOwnProperty.call(r, pv1)) {
            let v = r;
            if (pv2 && Object.hasOwnProperty.call(r, pv2)) {
                v = pvv2 ? (r[pv2][pvv2] || null) : r[pv2];
            } else if (pv2 && !Object.hasOwnProperty.call(r, pv2)) {
                v = null
            }
            if (pv3 && Object.hasOwnProperty.call(r, pv3)) {
                if (!Object.hasOwnProperty.call(out, r[pv3])) {
                    out[pvv3 ? (r[pv3][pvv3] || null) : r[pv3]] = {};
                }
                out[pvv3 ? (r[pv3][pvv3] || null) : r[pv3]][pvv1 ? (r[pv1][pvv1] || null) : r[pv1]] = v;
            } else {
                out[pvv1 ? (r[pv1][pvv1] || null) : r[pv1]] = v;
            }
        }
    }
    return out;
}

/**
 * Sorts given array object by key sortBy.
 *
 * @param {object|array} array Object to sort
 * @param {string} sortBy Sort by this key
 * @param {string} order Sort order asc/desc (ascending or descending).
 * @param {boolean} numeric_check Type of sorting to perform
 * @return {array|null} Sorted array, or null if not an array.
 */
export const sortByKey = function (array, sortBy, order = 'asc', numeric_check = false) {
    if (typeof array != 'object') {
        return null;
    }
    let iv = {};
    let sa = [];
    for (const key in array) {
        iv[key] = Object.hasOwnProperty.call(array[key], sortBy) ? array[key][sortBy] : '';
        if (numeric_check === true && typeof iv[key] == 'number') {
            iv[key] = Number(iv[key]) || 0;
        } else {
            numeric_check = false;
        }
        sa.push(iv[key]);
    }
    if (numeric_check === true) {
        sa.sort((a, b) => a - b);
    } else {
        sa.sort();
    }
    if (order !== 'asc') {
        sa.reverse();
    }
    let ordered_array = [];
    for (const k in iv) {
        let index = sa.indexOf(iv[k]);
        delete sa[index];
        ordered_array[index] = array[k];
    }
    return ordered_array;
}

/**
 * Convet a Array of Object containg id and parent_id as key in object into a Mapped Tree structure.
 * @param {Array} data Array of object containing id and parent_id in each row.
 * @param {Boolean} all (Optional) Flag to add All Node at top of tree.
 * @returns {Object} Object of mapped data with there children.
 */
export const mapTree = function (data, all = false) {
    let map = {},
        node, roots = [],
        i;
    for (i = 0; i < data.length; i += 1) {
        map[data[i].id] = i;
        data[i].children = [];
    }
    for (i = 0; i < data.length; i += 1) {
        node = data[i];
        if (node.parent_id !== "0" || node.parent_id !== 0) {
            if (data[map[node.parent_id]]) {
                data[map[node.parent_id]].children.push(node);
            }
        } else {
            roots.push(node);
        }
    }
    if (all) {
        roots = [{
            id: 0,
            parent_id: -1,
            name: 'All',
            children: roots
        }];
    }
    return roots;
}

/**
 * Generate random string of desired length and type.
 * 
 * @param {Number} PasswdLen lenth of password
 * @param {Enum} Type type of character in password N|AN|AaN Default will include special chars (@_#!:+=)
 * @returns {string} random string of desired length and char type.
 */
export const generate_password = function (PasswdLen, Type = '') {
    var CharStr = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmanopqrstuvwxyz@_#!:+=';
    var CharLen = CharStr.length;
    if (Type.trim() === 'N') {
        CharLen = 9;
    } else if (Type.trim() === 'AN') {
        CharLen = 35;
    } else if (Type.trim() === 'AaN') {
        CharLen = 61;
    }
    var PasswdStr = "";
    while (PasswdStr.length < PasswdLen) {
        var RandChar = CharStr.substr(mt_rand(0, CharLen), 1);
        if (PasswdStr.indexOf(RandChar) !== -1) {
            continue;
        }
        PasswdStr += RandChar;
    }
    return PasswdStr;
}

/**
 * Get a random number between a given range.
 * @param {Number} min min range
 * @param {Number} max max range
 * @returns {Number} a random no.
 */
export const mt_rand = function (min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

/** Date Functionality */
const day_suffix = { 1: "st", 2: "nd", 3: "rd", 21: "st", 22: "nd", 23: "rd", 31: "st" };
const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const days_name = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
export const months = { 1: "Jan", 2: "Feb", 3: "Mar", 4: "Apr", 5: "May", 6: "Jun", 7: "Jul", 8: "Aug", 9: "Sep", 10: "Oct", 11: "Nov", 12: "Dec" };
export const months_name = { 1: "January", 2: "February", 3: "March", 4: "April", 5: "May", 6: "June", 7: "July", 8: "August", 9: "September", 10: "October", 11: "November", 12: "December" };

/**
 * Returns a System Timezone.
 *
 * @return Timezone in (+-)HH:MM
 */
export const systemTimeZone = function () {
    let tzo = Number((new Date()).getTimezoneOffset());
    let tzs = '-';
    if (tzo <= 0) {
        tzs = '+';
        tzo *= -1;
    }
    return tzs + _dd(Math.floor(tzo / 60)) + ":" + _dd(tzo % 60);
}

/**
 * Returns a UNIX Timestamp of Current time.
 *
 * @param boolean microsec with/without Microsecond. Default is false
 * @return int UNIX Timestamp
 */
export const timestamp = function (IST = true, microsec = false) {
    let dt = new Date();
    if (IST) {
        dt.setMinutes(dt.getMinutes() + 330 + Number(dt.getTimezoneOffset()));
    }
    return microsec ? Number(dt / 1) : Math.round(dt / 1000);
}

/**
 * Returns a UNIX Timestamp of Current time.
 *
 * @return int UNIX Timestamp
 */
export const microtime = function (IST = true) {
    return timestamp(IST, true);
}

/**
 * Returns a date Array for given Datetime string.
 *
 * @param int|string|DateTime timestamp, a valid string or DateTime object
 * @return Array|null Mapped date array.
 */
export const date_obj_array = function (timestamp) {
    if (timestamp === undefined) {
        timestamp = timestamp();
    }
    let dt = check_timestamp(timestamp);
    if (!dt) {
        return '';
    }
    let dobj = {};
    dobj['Y'] = dt.getFullYear();
    dobj['y'] = dt.getYear();
    dobj['n'] = dt.getMonth() + 1;
    dobj['m'] = _dd(dobj.n);
    dobj['j'] = dt.getDate();
    dobj['d'] = _dd(dobj.j);
    dobj['w'] = dt.getDay();
    dobj['N'] = dobj.w;
    dobj['D'] = display(days[dobj.w]);
    dobj['l'] = display(days_name[dobj.w]);
    dobj['S'] = display(day_suffix[dobj.j] || "th");
    dobj['M'] = display(months[dobj.n]);
    dobj['F'] = display(months_name[dobj.n]);
    dobj['G'] = dt.getHours();
    dobj['H'] = _dd(dobj.G);
    dobj['i'] = _dd(dt.getMinutes());
    dobj['s'] = _dd(dt.getSeconds());
    dobj['g'] = dobj.G;
    dobj['t'] = _dd(new Date(dobj.Y, dobj.n, 0).getDate());
    dobj['A'] = 'AM';
    dobj['a'] = 'am';
    if (dobj['g'] >= 12) {
        dobj['g'] %= 12;
        dobj['A'] = 'PM';
        dobj['a'] = 'pm';
    }
    if (dobj['g'] === 0) {
        dobj['g'] = 12;
    }
    if (dobj.N === 0) {
        dobj.N = 7;
    }
    if (dobj.y >= 100) {
        dobj.y -= 100;
    }
    dobj['W'] = 1 + Math.round(((dt.getTime() - (new Date(dobj.Y, 0, 4)).getTime()) / 86400000 + 1) / 7);
    dobj['h'] = _dd(dobj.g);
    return dobj;
}

/**
 * Returns a nicely formatted date string for given Datetime string.
 *
 * @param int|string|DateTime timestamp, a valid string or DateTime object
 * @param string|DateTimeZone $timezone Timezone string or DateTimeZone object
 * @param string format_str The format to use. If null, `Y-m-d H:i:s` is used
 * @return string|null Formatted date string
 */
export const date_format = function (timestamp, format_str) {
    const valid_timestamp = (new Date(timestamp)).getTime() > 0;
    if (format_str === undefined) {
        format_str = "Y-m-d H:i:s";
    }
    if (!valid_timestamp) return "";
    let dobj = date_obj_array(timestamp);
    if (!dobj) {
        return '';
    }
    let date_str = '';
    let skeep = false;
    format_str.toString().split("").forEach(fm => {
        if (fm === '\\') {
            skeep = true;
        } else {
            date_str += (!skeep && Object.hasOwnProperty.call(dobj, fm)) ? dobj[fm] : fm;
            skeep = false;
        }
    });
    return date_str;
}

/**
 * Returns a nicely formatted Current date string for given Datetime format.
 *
 * @param string format_str The format to use. If null, `Y-m-d H:i:s` is used
 * @return string Formatted date string
 */
export const curdate = function (format_str) {
    return date_format(timestamp(), format_str);
}

/**
 * Validate and returns a  DateTime object for given Datetime string.
 *
 * @param int|string|DateTime timestamp, a valid string or DateTime object
 * @return DateTime object|null
 */
export const check_timestamp = function (timestamp) {
    if (timestamp === undefined) {
        return '';
    }
    let dt;
    if (typeof timestamp === 'string') {
        if (timestamp.length === 10) {
            timestamp += ' 00:00:00';
        }
        dt = new Date(timestamp);
    } else if (typeof timestamp === 'object') {
        dt = new Date(timestamp);
    } else {
        dt = new Date(timestamp * 1000);
    }
    if (dt == "Invalid Date") {
        return '';
    }
    let Y = dt.getFullYear();
    if (Y.toString().length > 4) {
        dt = new Date(timestamp);
    }
    Y = dt.getFullYear();
    let m = _dd(dt.getMonth() + 1);
    let d = _dd(dt.getDate());
    if (Y + '-' + m + '-' + d === '1970-01-01') {
        return '';
    }
    return dt;
}

/**
 * Returns Two digit string of date chunk.
 *
 * @param int|string d, Date Chunk
 * @return string Two digit string
 */
export const _dd = function (d) {
    return (d < 10 ? '0' : '') + d;
}

/**
 * Add month on given date
 * 
 * @param {string|number|time object} timestamp 
 * @param {Number} no_of_month no of month to be added
 * @param {*} format output format
 * @returns future or past date in desired format after adding month.
 */
export const add_month = function (timestamp, no_of_month = 1, format = 'Y-m-d') {
    let dt = check_timestamp(timestamp);
    if (!dt) {
        return '';
    }
    let m = dt.getMonth();
    dt.setMonth(m + parseInt(no_of_month));
    return date_format(dt, format);
}

/**
 * Add Date on given date
 * 
 * @param {string|number|time object} timestamp 
 * @param {Number} no_of_month no of days to be added
 * @param {*} format output format
 * @returns future or past date in desired format after adding days.
 */
export const add_date = function (timestamp, no_of_day = 1, format = 'Y-m-d') {
    let dt = check_timestamp(timestamp);
    if (!dt) {
        return '';
    }
    let d = dt.getDate();
    dt.setDate(d + parseInt(no_of_day));
    return date_format(dt, format);
}

/**
 * Add Hours on given date
 * 
 * @param {string|number|time object} timestamp 
 * @param {Number} no_of_month no of days to be added
 * @param {*} format output format
 * @returns future or past date in desired format after adding hours.
 */
export const add_hours = function (timestamp, no_of_hours = 1, format = 'Y-m-d H:i:s') {
    let dt = check_timestamp(timestamp);
    if (!dt) {
        return '';
    }
    let h = dt.getHours();
    dt.setHours(h + parseInt(no_of_hours));
    return date_format(dt, format);
}

/**
 * Add Minuts on given date
 * 
 * @param {string|number|time object} timestamp 
 * @param {Number} no_of_month no of days to be added
 * @param {*} format output format
 * @returns future or past date in desired format after adding minuts.
 */
export const add_minutes = function (timestamp, no_of_minutes = 1, format = 'Y-m-d H:i:s') {
    let dt = check_timestamp(timestamp);
    if (!dt) {
        return '';
    }
    let m = dt.getMinutes();
    dt.setMinutes(m + parseInt(no_of_minutes));
    return date_format(dt, format);
}

/**
 * Get diffrence between two days in desired unit.
 * @param {string|number|time object} date1 
 * @param {string|number|time object} date2 
 * @param {Char} diff_in time Unit Y|M|D|H|I|S default is microsecond
 * @returns {Number} diffrence in desired unit
 */
export const date_diff = function (date1, date2, diff_in) {
    if (diff_in === undefined) {
        diff_in = 'D';
    }
    let d1 = date_obj_array(date1);
    let d2 = date_obj_array(date2);
    let diff = ((Number(d1.Y) || 0) - (Number(d2.Y) || 0)) * (31104000);
    diff += ((Number(d1.n) || 0) - (Number(d2.n) || 0)) * (2592000);
    diff += ((Number(d1.j) || 0) - (Number(d2.j) || 0)) * (86400);
    diff += ((Number(d1.G) || 0) - (Number(d2.G) || 0)) * (3600);
    diff += ((Number(d1.i) || 0) - (Number(d2.i) || 0)) * (60);
    diff += ((Number(d1.s) || 0) - (Number(d2.s) || 0)) * (1);
    switch (diff_in) {
        case 'Y':
            diff /= 31104000;
            break;
        case 'M':
            diff /= 2592000;
            break;
        case 'D':
            diff /= 86400;
            break;
        case 'H':
            diff /= 3600;
            break;
        case 'I':
            diff /= 60;
            break;
        case 'S':
        default:
            diff /= 1;
    }
    return Math.round(diff * 10) / 10;
}

/**
 * Get diffrence between two days in readable format.
 * @param {string|number|time object} date1 
 * @param {string|number|time object} date2 
 * @returns {string} readable diffrence of dates.
 */
export const date_diff_tostring = function (date1, date2) {
    let diff = Math.abs(date_diff(date1, date2, 'S'));
    let out = '' + (diff % 60) + 's';
    diff = Math.floor(diff / 60);
    if (diff > 60) {
        out = '' + (diff % 60) + 'm ' + out;
        diff = Math.floor(diff / 60);
        if (diff > 24) {
            out = '' + (diff % 24) + 'h ' + out;
            diff = Math.floor(diff / 24);
            if (diff > 30) {
                out = '' + (diff % 30) + 'd ' + out;
                diff = Math.floor(diff / 30);
                if (diff > 12) {
                    out = '' + (diff % 12) + 'M ' + out;
                    diff = Math.floor(diff / 12);
                    if (diff > 0) {
                        out = '' + diff + 'Y ' + out;
                    }
                } else if (diff > 0) {
                    out = '' + (diff % 12) + 'M ' + out;
                }
            } else if (diff > 0) {
                out = '' + diff + 'd ' + out;
            }
        } else if (diff > 0) {
            out = '' + diff + 'h ' + out;
        }
    } else if (diff > 0) {
        out = '' + diff + 'm ' + out;
    }
    return out;
}


/**
 * Filter an array of objects to include only specified keys.
 * @param {Array<object>} arrayOfObjects - The array of objects to filter.
 * @param {Array<string|{[oldKey: string]: string}>} keys - The keys to include in the filtered objects.
 *   If a string, the key is included as is.
 *   If an object with a single key-value pair, the key is replaced with the specified value.
 * @returns {Array<object>} - The filtered array of objects.
 */

export function filterObjectsByKeys(arrayOfObjects, keys) {
    // Validate arrayOfObjects is an array of objects
    if (!Array.isArray(arrayOfObjects) || !arrayOfObjects.every(obj => typeof obj === 'object' && obj !== null)) {
        console.error('First argument must be an array of objects.');
        return [];
    }

    // Validate keys is an array
    if (!Array.isArray(keys)) {
        console.error('Second argument must be an array.');
        return [];
    }

    // Filter and map objects to only include specified keys that are present
    return arrayOfObjects.map(obj => {
        const filteredObj = {};
        keys.forEach(key => {
            if (typeof key === 'string') {
                // If key is a string, directly use it as is
                if (obj.hasOwnProperty(key)) {
                    filteredObj[key] = obj[key];
                }
            } else if (typeof key === 'object' && Object.keys(key).length === 1) {
                // If key is an object with a single key-value pair, use the mapping
                const oldKey = Object.keys(key)[0];
                const newKey = key[oldKey];
                if (obj.hasOwnProperty(oldKey)) {
                    filteredObj[newKey] = obj[oldKey];
                }
            }
        });
        return filteredObj;
    });
}



/* funtion for return lowecase value */

export function getLowerCaseValue(params) {
    if (params) {
        return params.toLowerCase();
    }
    return params;
}

export function getUpperCaseValue(params) {
    if (params) {
        return params.toUpperCase();
    }
    return params;
}

export function getFinancialYear() {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();

    if (month < 3) {
        return year - 1;
    } else {
        return year;
    }
}
export function getFinancialYearList(year, direction, count = null) {
    const today = new Date();
    const currentYear = today.getFullYear();

    if (count === null) {
        count = Math.abs(currentYear - year) + 1;
    }

    const financialYears = [];

    for (let i = 0; i < count; i++) {
        let startYear, endYear;
        if (direction === "previous") {
            startYear = year - i;
        } else if (direction === "next") {
            startYear = year + i;
        } else {
            throw new Error("Invalid direction. Use 'previous' or 'next'.");
        }
        endYear = startYear + 1;
        financialYears.push({ id: `${startYear}`, name: `${startYear}-${endYear}` });
    }

    return financialYears;
}

export function isValidDate(date, alternativeValue = null) {
    if (typeof date === 'string') {
        const parsedDate = new Date(date);
        return !isNaN(parsedDate.getTime()) ? date : alternativeValue;
    } else if (date instanceof Date) {

        return !isNaN(date.getTime()) ? date : alternativeValue;
    } else {

        return alternativeValue !== null ? alternativeValue : false;
    }
}

export function isObjOrArrayEmpty(obj, replace = "") {
    if (Array.isArray(obj)) {
        if (obj.length === 0) {
            if (replace) {
                return replace;
            }
            return [];
        }
        return obj;
    }
    else if (obj && typeof obj === 'object') {
        const res = Object.keys(obj).length === 0 && obj.constructor === Object;
        if (res) {
            if (replace) {
                return replace;
            }
            return {};
        }
        return obj;
    }
    else {
        return replace;
    }
}

export function get_month_year(before_count, after_count, target_date = null) {
    before_count = Math.round(before_count) || 0;
    after_count = Math.round(after_count) || 0;
    let current_month = target_date ? date_format(target_date, 'Y-m-01') : curdate('Y-m-01');
    let [year, month] = add_month(current_month, after_count, 'Y,n').split(',').map(Number);
    let months = [];
    const no_of_months = before_count + after_count + 1;
    for (let i = 0; i < no_of_months; i++) {
        if (month < 1) {
            month += 12;
            year -= 1;
        }
        let [id, name] = date_format(`${year}-${month}-01 00:00:00`, 'Y-m-d,M-Y').split(",");
        months.push({ id, name });
        month -= 1;
    }
    return { cur_month: current_month, months: months };
}

export function setSymbolOnEmpty(value, symbol) {
    if (value == "" || !value) {
        if (value == 0) return value;
        return symbol
    }
    return value;
}

export function valueWithCurrencySymbol(value, position = 'first', currency = <CurrencyRupee fontSize="inherit" />) {
    const ACTIVE_COUNTRY = getUser('active_country_id');
    return ACTIVE_COUNTRY == INDIAN_COUNTRY_ID ? (<Stack direction="row" alignItems="center">
        {position === 'last' ? (<>{value} {currency}</>) : (<>{currency} {value}</>)}
    </Stack>) : value
}



export function getYearList(year, step, number = 1) {
    const yearList = [year]
    year = Number(year);
    number = Number(number);
    for (let i = 0; i < number - 1; i++) {
        let data;
        if (step == "prev") {
            data = year - i - 1;
        }
        else if (step == "next") {
            data = year + i + 1;
        }
        yearList.push(data);
    }

    return yearList;
}

export const sec_to_hr_min = (sec) => {
    var s = sec % 60;
    sec = parseInt(sec / 60);
    var i = sec % 60;
    var H = parseInt(sec / 60);
    return (H > 9 ? H : ('0' + H)) + ':' + (i > 9 ? i : ('0' + i));
}

export function get_time_to_sec(time) {
    let t = (time || '0').split(':');
    return (Number(t[0] || 0) * 3600) + (Number(t[1] || 0) * 60) + Number(t[2] || 0);
}

export function getChunkArray(dataArray, chunk_size) {
    let i = 0;
    let arrayLength = dataArray.length;
    let tempArray = [];
    for (i = 0; i < arrayLength; i += chunk_size) {
        let chunData = dataArray.slice(i, i + chunk_size);
        tempArray.push(chunData);
    }
    return tempArray;
}

export function getFileIcon(filePath) {
    const fileExtension = getCommonFileExtension(filePath);
    switch (fileExtension) {
        case "pdf":
            return <img src={pdfIcons} />;
        case "doc":
        case "docx":
            return <img src={wordIcons} />;
        case "xls":
        case "xlsx":
        case "csv":
            return <img src={csvIcons} />;
        case "ppt":
        case "pptx":
            return <img src={imgIcons} />;
        case "jpg":
        case "jpeg":
        case "png":
        case "gif":
            return <img src={imgIcons} />;
        default:
            return <SaveAltIcon sx={{ fontSize: 25 }} />;
    }
}

export function numberFormater(number) {
    if (isNaN(number)) return 0;
    const [integerPart, decimalPart] = number.toString().split(".");
    const integerPartWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return integerPartWithCommas + (decimalPart ? "." + decimalPart : "");
}

export function addDotted(string, maxStringSize, tooltip = true, added = "...",) {
    string = `${string || ''}`;
    if (maxStringSize >= string?.length) return string
    else {
        if (tooltip && string) {
            return (
                <Tooltip title={string} arrow placement="top" sx={{ cursor: "pointer" }}>
                    <span>{string?.substr(0, maxStringSize) + added}</span>
                </Tooltip>
            );
        }
        else return string?.substr(0, maxStringSize) + added
    }
}

export function replaceKeys(inputArray, keyMapping) {
    const replacedArray = [];

    for (const item of inputArray) {
        const newItem = {};
        for (const key in item) {
            if (keyMapping.hasOwnProperty(key)) {
                newItem[keyMapping[key]] = item[key];
            } else {
                newItem[key] = item[key];
            }
        }
        replacedArray.push(newItem);
    }

    return replacedArray;
}

export function countMonthsBetween(targetDateString, startDateString = null) {
    const currentDate = new Date();
    const startDate = startDateString ? new Date(startDateString) : currentDate;
    const targetDate = new Date(targetDateString);

    const diffMonths = (targetDate.getFullYear() - startDate.getFullYear()) * 12 +
        (targetDate.getMonth() - startDate.getMonth());

    return diffMonths;
}

export function daysRemainingUntilTenureMonth(date, tenureMonth) {
    return tenureMonth;
}


export function getAllRequiredKeys(filterValues) {
    const targetKey = 'required';
    const targetValue = true;

    const reuiredKey = [];

    /* `getting required fields name from dynamic filters which we getting @res variable */

    for (let filters of filterValues) {
        if (filters.hasOwnProperty(targetKey) && filters[targetKey] === targetValue) {
            if (filters?.name) reuiredKey.push(filters?.name);
        }
    }

    return reuiredKey;
}


export function getUrl(url) {
    return url;
}
export const debounce = (func, delay) => {
    let timeoutId;

    return function (...args) {
        const context = this;

        clearTimeout(timeoutId);

        timeoutId = setTimeout(() => {
            func.apply(context, args);
        }, delay);
    };
};

export default debounce;

export function throttle(func, wait) {
    let timeout;
    let context;
    let args;

    const throttled = function () {
        context = this;
        args = arguments;
        if (!timeout) {
            timeout = setTimeout(() => {
                func.apply(context, args);
                timeout = null;
            }, wait);
        }
    };

    return throttled;
}



export function isInt(n) {
    return Number(n) === n && n % 1 === 0;
}

export function isFloat(n) {
    return Number(n) === n && n % 1 !== 0;
}

export function getCommonFileExtension(url) {
    const fileExtension = url.split('.').pop();
    const fileExtensionsMapping = {
        'xlsx': 'xls',
        'sheet': 'xls',
        'docx': 'doc',
        'pptx': 'ppt',
        'ms-excel': 'xls',
        'ms-word': 'doc',
        'ms-powerpoint': 'ppt',
        'ms-csv': 'csv'
    };
    if (fileExtension in fileExtensionsMapping) {
        return fileExtensionsMapping[fileExtension];
    }
    return fileExtension;
}

export function setSymbolOnEmptyWithCurrency(value, symbol, position = 'first', currency) {
    if (value == "" || !value) {
        if (value != 0) {
            value = symbol
        }
    }
    return <Stack direction="row" alignItems="center">
        {position === 'last' ? (<>{value} {currency ? currency : <CurrencyRupee fontSize="inherit" />}</>) : (<>{currency ? currency : <CurrencyRupee fontSize="inherit" />} {value}</>)}
    </Stack>

}

export function getCountryWiseData(data, country_id) {
    if (data[country_id]) return data[country_id];
    else if (data['default']) return data['default'];
    else return "";
}

/**
 * Checks if the input is null, undefined, or an empty string.
 * @param {any} value - The input value to check.
 * @returns {boolean} - True if the value is null, undefined, or an empty string; otherwise, false.
 */

export function isEmpty(value) {
    return value === null || value === undefined || value === "";
}

export function downloadExcel(headers, data, filename) {
    const keys = headers.map(header => header.key);

    // Convert data to an array of arrays
    const dataArray = data.map(item => keys.map(key => item[key]));

    // Convert headers to an array of labels
    const headerLabels = headers.map(header => header.label);

    // Insert header row at the beginning of the data array
    dataArray.unshift(headerLabels);

    // Convert data array to worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(dataArray);

    // Create a workbook
    const workbook = XLSX.utils.book_new();

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Save the workbook as a file
    XLSX.writeFile(workbook, `${filename}.xlsx`);
}
